.not-found {
  .not-found-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--vh);

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .not-found-err {
      font-family: 'MM fonts';
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        span {
          font-size: 700rem;
          font-weight: 300;
          letter-spacing: 100rem;
          transform: translateY(150%);
          pointer-events: none;
          display: inline-block;

          @media (max-width: 600px) {
            font-size: 140px;
            letter-spacing: 10px;
          }
        }
      }

      @media (max-width: 600px) {
        margin-bottom: 100px;
      }
    }

    .not-found-text {
      @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      p {
        font-size: 32rem;
        transform: translateY(10rem);
        opacity: 0;

        @media (max-width: 600px) {
          font-size: 21px;
        }
      }

      .button-wrapper {
        margin-top: 360rem;

        @media (max-width: 600px) {
          margin-top: 50px;
        }

        .colorBtn {
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: -100%;
          left: 0%;
          border-radius: 50%;
          transition: var(--transition-long);
        }
        .colorBtn:nth-child(1) {
          background: var(--accent-color);
        }
        .colorBtn:nth-child(2) {
          background: var(--link-hover);
          transition-delay: 0.2s;
        }

        @media (min-width: 768px) {
          .button:hover .colorBtn:nth-child(1),
          .button:hover .colorBtn:nth-child(2),
          .button:hover .colorBtn:nth-child(3) {
            bottom: 0;
          }
        }

        .button {
          width: 150rem;
          height: 150rem;
          background-color: var(--btn-bg);
          position: relative;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--white-color);
          transform: unset;

          @media (max-width: 600px) {
            width: 70px;
            height: 70px;
          }

          .button-text {
            font-size: 24rem;
            letter-spacing: 1rem;
            font-weight: 100;
            position: relative;
            z-index: 100;

            @media (max-width: 600px) {
              font-size: 14px;
              line-height: 1;
            }
          }
        }
      }
    }
  }
}
