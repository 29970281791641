.about-more {
  position: relative;
  padding-top: 200rem;

  &__img {
    height: 200vh;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
      height: 150vh;
    }
    @media (max-width: 500px) {
      height: 95vh;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.712);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(1);
      transform: unset;
    }
  }
  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: var(--white-color);
    text-align: center;
    font-family: 'MM fonts';
    font-size: 150rem;
    line-height: 0.9;
    text-transform: uppercase;
    width: 1200rem;

    @media (max-width: 1100px) {
      width: 90%;
      font-size: 90px;
    }
    @media (max-width: 768px) {
      font-size: 60px;
    }
    @media (max-width: 450px) {
      font-size: 40px;
    }

    &_spots {
      position: absolute;
      top: 0;
      left: 0;
      width: max-content;
      height: 100%;
      pointer-events: none;

      @media (max-width: 768px) {
        width: 150px;
      }
    }
  }
}
