.footer {
  width: 100%;
  height: 180rem;
  background: var(--btn-bg);
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: none;
  padding: 100rem 15rem 0;

  @media (max-width: 1100px) {
    height: 150px;
    padding: 75px 20px 0;
  }

  @media (max-width: 600px) {
    height: 260px;
    padding: 120px 20px 0;
  }

  &:before {
    content: '';
    position: absolute;
    background-color: rgba(24, 25, 25, 0.55);
    border-radius: 50%;
    z-index: -1;
  }

  &:before {
    right: -100rem;
    bottom: -150rem;
    width: 450rem;
    height: 450rem;

    @media (max-width: 500px) {
      right: -200px;
      bottom: -200px;
      width: 400px;
      height: 400px;
    }
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  &-block {
    display: flex;
    flex-direction: column;
    width: max-content;
    p {
      color: #878889;
      font-size: 16rem;
      margin-bottom: 10rem;

      @media (max-width: 1100px) {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    .date {
      display: flex;
      margin-bottom: 5rem;

      @media (max-width: 1100px) {
        margin-bottom: 0;
      }

      @media (max-width: 600px) {
        margin-bottom: 30px;
      }

      div {
        margin-right: 10rem;

        @media (max-width: 1100px) {
          margin-right: 10px;
          font-size: 16px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.footer-active {
  display: block;
}
