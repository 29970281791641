:root {
  --col-1: calc(100vw / 12 * 1);
  --col-2: calc(100vw / 12 * 2);
  --col-3: calc(100vw / 12 * 3);
  --col-4: calc(100vw / 12 * 4);
  --col-5: calc(100vw / 12 * 5);
  --col-6: calc(100vw / 12 * 6);
  --col-7: calc(100vw / 12 * 7);
  --col-8: calc(100vw / 12 * 8);
  --col-9: calc(100vw / 12 * 9);
  --col-10: calc(100vw / 12 * 10);
  --col-11: calc(100vw / 12 * 11);
  --col-12: calc(100vw / 12 * 12);
}

.slider {
  --slidesTotal: 5;
  will-change: transform;

  display: flex;
  align-items: center;

  position: absolute;

  width: 100%;
  height: 100%;

  overflow-x: unset;

  @media (max-width: 1100px) {
    align-items: flex-start;
  }

  &-drag {
    font-family: 'MM fonts';
    font-size: 90rem;
    text-transform: uppercase;
    padding-left: 15rem;
    padding-right: 50rem;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  &__container {
    display: grid;
    align-items: center;
    background-color: #fff;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;

    will-change: transform;

    @media (max-width: 768px) {
      min-width: 350px;
    }
  }

  &__item-img-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__container {
    grid-template-columns: repeat(5, var(--col-9));
    grid-column-gap: 50rem;
    height: 100%;
    cursor: grab;
    position: relative;

    @media (max-width: 768px) {
      grid-column-gap: 90px;
      align-items: flex-end;
    }

    .drag-icon {
      position: absolute;
      top: 0;
      left: -40rem;
      width: 70rem;
      z-index: 10000;
      transform: scale(0);
      mix-blend-mode: difference;

      &::after {
        content: 'drag';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -40%);
        text-transform: uppercase;
        font-family: 'MM fonts';
        font-size: 50rem;
        color: #fff;
      }

      span {
        position: absolute;
        top: 50%;
        width: 10rem;
        height: 15rem;
        transform: translateY(-50%);

        &:first-child {
          left: -15rem;

          &::after,
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2rem;
            background-color: #fff;
          }

          &::after {
            top: 12rem;
            transform: rotate(45deg);
          }
          &::before {
            top: 6rem;
            transform: rotate(-45deg);
          }
        }
        &:last-child {
          right: -15rem;

          &::after,
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2rem;
            background-color: #fff;
          }

          &::after {
            top: 12rem;
            transform: rotate(135deg);
          }
          &::before {
            top: 6rem;
            transform: rotate(-135deg);
          }
        }
      }
    }
  }

  &__item-img {
    max-width: unset;
    pointer-events: none;
    user-select: none;

    transform-origin: left center;
    transform: scale(1.3);
    will-change: transform;
  }
}

.slider-gallery {
  position: relative;
  height: 90vh;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 200px;
    padding-top: 50px;
  }
}

.is-dragging .slider__container {
  cursor: grabbing;
}

.is-device .slider__container {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: scroll;
}

[style*='--aspect-ratio'] > *:first-child,
.cover {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 0 150rem;
  object-fit: contain;

  @media (max-width: 768px) {
    padding: 0 30px;
    height: 100%;
  }
}

@supports (--custom: property) {
  [style*='--aspect-ratio'] {
    position: relative;
    overflow: hidden;
  }

  [style*='--aspect-ratio']::before {
    content: '';

    display: block;

    padding-bottom: calc(100% / var(--aspect-ratio));
  }
}

@media (max-width: 991px) {
  .drag-icon {
    display: none; // Скрытие иконки drag-and-drop, так как управление происходит через скролл
  }
}
