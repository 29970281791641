.form {
  padding: 100rem 0 150rem;
  margin-bottom: 100rem;
  border-bottom-left-radius: 30rem;
  border-bottom-right-radius: 30rem;
  position: relative;
  background-color: var(--bg);
  overflow: hidden;

  &__noise {
    position: absolute;
    bottom: 0%;
    right: 0%;
    width: 20%;
    height: max-content;
    pointer-events: none;

    @media (max-width: 768px) {
      width: 50%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 100rem;
    left: -150rem;
    width: 300rem;
    height: 300rem;
    background-color: #1a2023;
    border-radius: 50%;
    z-index: -1;

    @media (max-width: 768px) {
      bottom: 120px;
      height: 80px;
      left: -40px;
      width: 80px;
    }
  }

  .container {
    position: unset;
    z-index: 2;
  }

  @media (max-width: 1100px) {
    padding: 100px 0;
    margin-bottom: 100px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  @media (max-width: 768px) {
    padding-bottom: 50px;
  }

  @media (max-width: 600px) {
    margin-bottom: 160px;
    padding-top: 100px;
  }

  &-title {
    font-family: 'MM fonts';
    font-size: 300rem;
    line-height: 1;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    border-top: 1rem solid rgb(214, 214, 214);
    padding-top: 50rem;
    position: relative;

    svg {
      position: absolute;
      top: 80rem;
      left: 650rem;
      transform: translateX(-50%);
      stroke-dasharray: 0 1000px;
      transition: all 2s cubic-bezier(0.05, 0.72, 0.32, 0.91);
      stroke-dashoffset: 10px;

      @media (max-width: 1100px) {
        top: -50px;
        left: 400px;
        width: 300px;
        height: 300px;
      }

      @media (max-width: 991px) {
        top: -40px;
        left: 300px;
        width: 200px;
        height: 200px;
      }

      @media (max-width: 700px) {
        left: 250px;
      }

      @media (max-width: 600px) {
        left: 200px;
      }

      @media (max-width: 500px) {
        left: 220px;
        top: -20px;
        width: 170px;
        height: 170px;
      }
    }

    @media (max-width: 768px) {
      font-size: 100px;
      padding-top: 20px;
      border-top-width: 1px;
    }
  }

  &-wrapper {
    display: flex;
    align-items: flex-start;
    height: 60vh;
    position: relative;
    z-index: 3;
    margin-top: 100rem;

    @media (max-width: 1100px) {
      margin-top: 30px;
    }

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      height: unset;
      margin-top: 10px;
    }
  }

  &-canvas {
    height: 100%;
    width: 55% !important;
    position: relative;
    margin-left: auto;

    p {
      display: none;

      span {
        font-size: 20px;
      }
    }

    canvas {
      width: 100%;
      height: 100%;
      border-radius: 20rem;
    }

    @media (max-width: 1100px) {
      canvas {
        border-radius: 20px;
      }
    }

    @media (max-width: 768px) {
      width: 100% !important;
      height: 300px !important;

      p {
        display: block;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        margin: 10px 0 0;
        font-weight: 500;
      }
    }

    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.svg-draw svg {
  stroke-dasharray: 1000px 1000px;
}
