:root {
  --white-color: #ffffff;
  --accent-color: #f9f971ea;
  /* --accent-color: #ecfb5c; */
  --text-color: #2c3333;
  --text-color-hover: #06060a;
  --btn-bg: #17242a;
  --bg: #feffff;
  --link-hover: #104d54;
  --about-page-color: #f0f1fa;
  --transition-long: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  --transition-long-title: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  --transition-medium: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  --transition-fast: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

::selection {
  background: var(--btn-bg);
  color: var(--white-color);
}

.element::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar {
  width: 0;
}

html,
body {
  font-family: 'Aeonik';
  background-color: var(--bg);
  color: var(--text-color);
  height: auto;
}

.App {
  position: relative;
  overflow: hidden;
}

.App:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background-image: url('../../public/noisy.png');
  background-position: center;
  background-repeat: repeat;
  z-index: 100000;
  pointer-events: none;

  filter: opacity(0.5);
}

input:hover {
  cursor: text;
}

#root {
  overflow-x: hidden;
}

section {
  position: relative;
  z-index: 2;
  background-color: var(--white-color);
}

.btn-first {
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 10000000000000000;
  font-size: 50px;
}

.mouse {
  position: fixed;
  z-index: 1000000;
  pointer-events: none;
}

.work-recent {
  font-family: 'MM fonts';
  font-size: 90rem;
  text-transform: uppercase;
  position: relative;
  width: max-content;
  padding-top: 25rem !important;
}

.work-recent svg {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-50%) rotate(-5deg) scale(0.8);
  stroke-dasharray: 0px 1000px;
  stroke-dashoffset: 10px;
  transition: all 1.3s cubic-bezier(0.05, 0.72, 0.32, 0.91);
}

.canvas {
  width: 100%;
  height: 100%;
}

.container {
  max-width: 1730rem;
  margin: 0 auto;
  padding: 0 10rem;
}

.social-buttons {
  display: flex;
  justify-content: flex-end;
}

.social-text {
  color: #878889;
  margin-bottom: 10rem;
  font-size: 16rem;
}

.logo-width .about:after {
  transform: scale(1) !important;
}

@media (min-width: 1100px) {
  .social a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1rem;
    background-color: #fff;
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform-origin: right center;
    transform: scaleX(0);
  }

  .social a:hover::after {
    transform-origin: left center;
    transform: scaleX(1);
  }
}

.social .button-wrapper {
  margin-right: 30rem;
}

.social .button-wrapper:last-child {
  margin-right: 0;
}

.button {
  cursor: pointer;
}

.colorBtn {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -100%;
  left: 0%;
  border-radius: 50%;
  transition: var(--transition-long);
}
.colorBtn:nth-child(1) {
  background: var(--accent-color);
}
.colorBtn:nth-child(2) {
  background: var(--link-hover);
  transition-delay: 0.2s;
}

@media (min-width: 768px) {
  .button:hover .colorBtn:nth-child(1),
  .button:hover .colorBtn:nth-child(2),
  .button:hover .colorBtn:nth-child(3) {
    bottom: 0;
  }
}

.slide-page-transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f9f971;
  z-index: 100000000;
  opacity: 0;
}
.slide-page-transition-second {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--vh);
  background-color: var(--btn-bg);
  z-index: 10000000;
  opacity: 0;
}

.app-active .slide-page-transition,
.app-active .slide-page-transition-second {
  opacity: 1;
}

@media (max-width: 1100px) {
  .container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }

  .social-text {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .social a::after {
    height: 1px;
  }

  .social .button-wrapper {
    margin-right: 15px;
  }

  .button-wrapper a {
    font-size: 14px;
  }
}
