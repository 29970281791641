.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--vh);
  z-index: 100000;
  background-color: var(--white-color);
  overflow: hidden;
  // display: none;

  .counter {
    display: flex;
    font-family: 'MM fonts';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-number,
    &-number-second {
      overflow: hidden;
      height: 65rem;

      @media (max-width: 1100px) {
        height: 45px;
      }
    }

    span {
      font-size: 60rem;
      text-transform: uppercase;
      display: block;
      transform: translateY(110%);

      @media (max-width: 1100px) {
        font-size: 38px;
      }
    }
  }

  .start-btn {
    font-family: 'MM fonts';
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    font-size: 42rem;
    text-transform: uppercase;
    overflow: hidden;

    @media (max-width: 1100px) {
      font-size: 28px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 5rem;
      left: 0;
      width: 100%;
      height: 3rem;
      background-color: var(--btn-bg);
      transition: var(--transition-long);

      @media (max-width: 1100px) {
        height: 3px;
        bottom: 2px;
      }
    }

    &:hover {
      &::after {
        left: 120%;
        transition-delay: 0;
      }
    }

    &:not(:hover) {
      &::after {
        left: 0;
        transition-delay: 0.6s;
      }
    }
  }

  .loading {
    position: absolute;
    bottom: -10rem;
    left: 20rem;

    @media (max-width: 1100px) {
      bottom: -5px;
      left: 10px;
    }

    span {
      font-family: 'MM fonts';
      font-size: 90rem;
      text-transform: uppercase;
      transform: translateY(100%);
      display: inline-block;

      @media (max-width: 1100px) {
        font-size: 50px;
      }
    }
  }
}
