.page-bar {
  width: 8rem;
  height: 150rem;
  background-color: rgb(216, 216, 216);
  position: fixed;
  top: 50%;
  right: 20rem;
  transform: translateY(-50%);
  border-radius: 20rem;
  z-index: 1000;
  // display: none;

  &-line {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--btn-bg);
    width: 100%;
    height: 10%;
    border-radius: 20rem;
  }

  @media (max-width: 1100px) {
    display: none;
  }
}
