.particles {
  position: relative;
  background-color: #fff;
  padding-bottom: 100rem;

  @media (max-width: 1100px) {
    padding-bottom: 0;
  }

  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 600rem;
  //   right: 150rem;
  //   width: 150rem;
  //   height: 150rem;
  //   background-color: #1a2023;
  //   border-radius: 50%;
  //   z-index: -1;
  //   transform: scale(0);
  //   transition: var(--transition-long);
  //   transition-delay: 0.3s;

  //   @media (max-width: 1100px) {
  //     top: 350px;
  //     right: -50px;
  //     width: 100px;
  //     height: 100px;
  //   }
  // }

  // &::before {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   left: 650rem;
  //   width: 200rem;
  //   height: 200rem;
  //   background-color: #1a2023;
  //   border-radius: 50%;
  //   z-index: -1;
  //   transform: scale(0);
  //   transition: var(--transition-long);

  //   @media (max-width: 1100px) {
  //     bottom: 150px;
  //     left: -25px;
  //     width: 50px;
  //     height: 50px;
  //   }
  // }

  h2 {
    font-family: 'MM fonts';
    font-size: 200rem;
    line-height: 1;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    padding-top: 50rem;
    position: relative;

    @media (max-width: 1100px) {
      padding: 100px 15px 0;
      font-size: 100px;
    }

    @media (max-width: 768px) {
      font-size: 70px;
      margin-bottom: 30px;
    }

    svg {
      position: absolute;
      bottom: -120rem;
      left: 600rem;
      width: 500rem;
      height: 100%;
      stroke-dasharray: 0px 1000px;
      stroke-dashoffset: 100px;

      @media (max-width: 1100px) {
        left: 350px;
        bottom: -100px;
        width: 250px;
      }

      @media (max-width: 991px) {
        left: 300px;
      }

      @media (max-width: 900px) {
        left: 230px;
      }

      @media (max-width: 768px) {
        left: 250px;
        width: 170px;
        bottom: -85px;
      }

      @media (max-width: 600px) {
        left: 160px;
        width: 170px;
        bottom: -40px;
      }
    }
  }

  &-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    canvas {
      width: 100%;
      height: 100%;
    }
  }

  .particles-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 450rem;
    margin-top: 100rem;

    @media (max-width: 1100px) {
      gap: 200px;
      margin-top: 50px;
    }

    @media (max-width: 768px) {
      display: block;
      gap: unset;
    }
  }

  .particles-block {
    border-bottom: 1rem solid rgb(219, 219, 219);
    padding-bottom: 20rem;
    width: 450rem;

    @media (max-width: 1100px) {
      padding-bottom: 10px;
      border-width: 1px;
      width: 280px;
    }

    @media (max-width: 800px) {
      width: 100%;
    }

    @media (max-width: 768px) {
      margin-bottom: 30px;
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:nth-child(2),
    &:nth-child(4) {
      margin-left: auto;
    }

    .particles-block__item {
      overflow: hidden;

      span {
        font-size: 32rem;
        display: inline-block;
        transform: translateY(120%);
        margin-right: 10rem;

        @media (max-width: 1100px) {
          font-size: 18px;
          margin-right: 5px;
        }
      }
    }
  }
}

.scale-circle {
  &:after {
    transform: scale(1);
  }
}
