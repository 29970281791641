.about-page {
  &-hero {
    position: relative;
    width: 100%;
    height: var(--vh);
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &::after {
      content: '';
      position: absolute;
      top: 300rem;
      left: 350rem;
      width: 100rem;
      height: 100rem;
      background-color: #1a2023;
      border-radius: 50%;
      transition: var(--transition-long);
      z-index: 2;

      @media (max-width: 1100px) {
        top: 10%;
        left: 20%;
        width: 100px;
        height: 100px;
      }
    }

    &__noise {
      position: absolute;
      top: 0;
      left: 30%;
      transform: rotate(180deg);
      width: 500rem;
      height: 100%;
      pointer-events: none;
      opacity: 0.3;

      @media (max-width: 768px) {
        width: 300px;
        opacity: 0.5;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 50rem;
      right: 350rem;
      width: 200rem;
      height: 200rem;
      background-color: #1a2023;
      border-radius: 50%;
      transition: var(--transition-long);
      z-index: 2;

      @media (max-width: 1100px) {
        top: unset;
        right: 20%;
        bottom: 10%;
        width: 70px;
        height: 70px;
      }
    }

    &-white {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: var(--white-color);
    }
  }

  .about-page-info {
    background-color: var(--bg);
    padding-top: 200rem;
    position: relative;
    padding-bottom: 200rem;

    @media (max-width: 1100px) {
      padding-top: 100px;
      padding-bottom: 0;
    }
    @media (max-width: 768px) {
      padding-top: 50px;
    }

    &-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      p {
        width: 45%;
        font-family: 'MM fonts';
        font-size: 70rem;
        text-transform: uppercase;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 700rem;
          right: 50rem;
          width: 150rem;
          height: 150rem;
          background-color: #1a2023;
          border-radius: 50%;
          transition: var(--transition-long);
          transform: scale(0);

          @media (max-width: 768px) {
            top: 230px;
            right: -50px;
            width: 50px;
            height: 50px;
            z-index: -1;
          }
        }

        @media (max-width: 1100px) {
          width: 70%;
          font-size: 31px;
          padding-right: 15px;
          margin-bottom: 50px;
        }

        @media (max-width: 768px) {
          width: 100%;
          padding-right: 0;
        }
      }

      .about-page-info-img {
        width: 50%;
        height: 1000rem;
        border-radius: 20rem;
        overflow: hidden;

        @media (max-width: 768px) {
          width: 50%;
          height: 450px;
          align-self: flex-end;
          transform: translateY(0);
          border-radius: 15px;
        }

        @media (max-width: 600px) {
          width: 100%;
        }

        img {
          border-radius: 20rem;
          object-fit: cover;
          width: 100%;
          height: 100%;
          transform: scale(1.1);

          @media (max-width: 768px) {
            border-radius: 15px;
          }
        }
      }
    }
  }
}

.about-page-path-wrapper {
  margin-left: auto;
  text-align: right;
  margin-top: 150rem;
  position: relative;

  @media (max-width: 1100px) {
    margin-top: 50px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100rem;
    left: 50rem;
    width: 250rem;
    height: 250rem;
    background-color: #1a2023;
    border-radius: 50%;
    transition: var(--transition-long);
    transform: scale(0);

    @media (max-width: 1100px) {
      top: -200px;
      right: 0;
      width: 100px;
      height: 100px;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  p {
    width: 70%;
    font-family: 'MM fonts';
    font-size: 70rem;
    text-transform: uppercase;
    margin-left: auto;

    @media (max-width: 1100px) {
      font-size: 31px;
    }

    @media (max-width: 1100px) {
      width: 100%;
      text-align: left;
    }
  }
}

.active-plane {
  top: 0%;
}

.scale-circle {
  &::after,
  &::before {
    transform: scale(1) !important;
  }
}
