.work-page {
  padding-top: 300rem;
  z-index: 3;
  position: relative;

  svg {
    stroke-dasharray: 0 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: -200rem;
    left: 50%;
    transform: translateX(-50%);
    width: 300rem;
    height: 300rem;
    background-color: #1a2023;
    border-radius: 50%;
    z-index: -1;

    @media (max-width: 1100px) {
      width: 100px;
      height: 100px;
      top: -70px;
    }
  }

  @media (max-width: 1100px) {
    padding-top: 130px;
  }

  &__wrapper {
    padding: 0 0 150rem;

    @media (max-width: 1100px) {
      padding: 0 0 0;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1rem solid rgb(214, 214, 214);
    padding-bottom: 100rem;

    @media (max-width: 1100px) {
      border-width: 1px;
      padding-bottom: 50px;
      border-bottom: none;
    }

    .work-recent {
      margin: 0;
      border: none;
      padding: 0;
    }
  }

  &__switch {
    display: flex;
    justify-content: flex-end;
    padding-right: 50rem;
    position: relative;

    &:after {
      content: 'viewing';
      position: absolute;
      top: -60rem;
      left: 50%;
      transform: translateX(-80%);
      font-family: 'MM fonts';
      font-size: 50rem;
      text-transform: uppercase;

      @media (max-width: 1100px) {
        display: none;
      }
    }

    @media (max-width: 1100px) {
      padding-right: 0;
    }

    .button-wrapper {
      &:first-child {
        margin-right: 20rem;

        @media (max-width: 1100px) {
          margin-right: 10px;
        }
      }
      div {
        width: 90rem;
        height: 90rem;
        background-color: var(--btn-bg);
        border-radius: 50%;
        overflow: hidden;
        position: relative;

        @media (max-width: 1100px) {
          width: 50px;
          height: 50px;
        }
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        width: 25rem;
        height: 30rem;

        @media (max-width: 1100px) {
          width: 20px;
          height: 15px;
        }

        line {
          stroke: #fff;
        }

        rect {
          stroke: #fff;
        }
      }
    }
  }
}

.active-switch {
  .colorBtn {
    bottom: 0;
  }
}

.work-page__wrapper {
  background-color: var(--white-color);
  transition: 1.5s cubic-bezier(0.71, 0.06, 0.06, 0.93);
}

.work-grid .categories-block__text_first-title span {
  bottom: 120%;
  // opacity: 0;
}

.work-grid .categories-wrapper {
  pointer-events: none;
}
.work-list .categories-wrapper {
  pointer-events: all;
}

.work-grid .categories-block:after {
  width: 0;
  transition-delay: 0s !important;
}

.work-grid .work-grid__wrapper {
  opacity: 1;
  top: 0;
  transition-delay: 0.2s;
}

.work-grid.work-page__wrapper {
  padding-bottom: 1100rem;

  @media (max-width: 1100px) {
    padding-bottom: 680px;
  }
  @media (max-width: 768px) {
    padding-bottom: 500px;
  }
  @media (max-width: 768px) and (min-height: 800px) {
    padding-bottom: 500px;
  }
  @media (max-width: 600px) {
    padding-bottom: 1700px;
  }
  @media (max-width: 500px) {
    padding-bottom: 1750px;
  }
}

.work-list .work-grid__wrapper {
  transition: 1s cubic-bezier(0.71, 0.06, 0.06, 0.93);
  pointer-events: none;
}

.categories-wrapper {
  transition: 1.5s cubic-bezier(0.71, 0.06, 0.06, 0.93);
}

.work-grid__wrapper .work-grid__block:nth-child(3) img {
  object-position: 0 35%;
}

.work-page__wrapper {
  position: relative;
}

.work-grid__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 150rem;
  transition: 1.5s cubic-bezier(0.71, 0.06, 0.06, 0.93);
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  padding-top: 40rem;
  z-index: 100;

  @media (max-width: 1100px) {
    gap: 100px;
    padding-top: 30px;
  }

  @media (max-width: 768px) {
    gap: 50px;
    padding-top: 0;
  }

  .work-grid__block {
    width: 45%;
    height: 650rem;
    position: relative;

    @media (max-width: 1100px) {
      height: 300px;
      width: 40%;
    }

    @media (max-width: 768px) {
      width: 42%;
      height: 350px;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    h2 {
      font-family: 'MM fonts';
      font-size: 70rem;
      text-transform: uppercase;
      overflow: hidden;
      position: relative;
      line-height: 0.5;
      margin-top: 20rem;
      padding-top: 15rem;
      transition: 0.7s cubic-bezier(0.71, 0.06, 0.06, 0.93);
      transition-delay: 0.2s;
      height: 55rem;

      @media (max-width: 1100px) {
        font-size: 40px;
        padding-top: 10px;
        height: 50px;
      }

      @media (max-width: 768px) {
        padding-top: 20px;
        // height: 50px;
      }

      svg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 30rem;
        height: 30rem;
        transform: translate(-100%, 100%);
        transition: 0.7s cubic-bezier(0.71, 0.06, 0.06, 0.93);
        transition-delay: 0.2s;

        @media (max-width: 1100px) {
          width: 18px;
          height: 18px;
        }
      }
    }

    span {
      position: absolute;
      right: 0;
      bottom: -55rem;
      font-size: 24rem;
      text-transform: capitalize;
      font-weight: 400;
      width: 70%;
      text-align: end;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &:nth-child(3),
    &:nth-child(6) {
      span {
        bottom: -65rem;
      }
    }

    &:hover {
      img {
        transform: scale(1.2);
      }

      h2 {
        padding-left: 45rem;
        transition-delay: 0;
        letter-spacing: 2rem;

        @media (max-width: 1100px) {
          padding-left: 30px;
          letter-spacing: 2px;
        }
      }

      svg {
        transform: translate(0, -10rem);
        transition-delay: 0.25s;

        @media (max-width: 1100px) {
          transform: translate(3px, -20px);
        }

        @media (max-width: 768px) {
          transform: translate(3px, -10px);
        }
      }
    }

    &-img {
      overflow: hidden;
      border-radius: 20rem;
      height: 650rem;

      @media (max-width: 1100px) {
        height: 300px;
        border-radius: 15px;
      }

      @media (max-width: 768px) {
        height: 300px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center !important;
        border-radius: 20rem;
        transition: 1s cubic-bezier(0.71, 0.06, 0.06, 0.93);
      }
    }
  }
}

.work-page-form {
  @media (max-width: 1100px) {
    .form {
      padding-top: 100px;
    }
  }
}
