@font-face {
  font-family: 'Aeonik';
  src: url('../../public/fonts/Aeonik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../public/fonts/Aeonik-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'MM fonts';
  src: url('../../public/fonts/MM-fonts.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
